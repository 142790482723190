/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import "./scss/style.scss";
import "react-virtualized/styles.css";
import "./App.css";
import "./response.css";
import "./views/KBView/KBstyle.css";
import "./views/KBView/KBNewStyle.css";
import { CCol, CRow } from "@coreui/react";
import { Authcontext } from "../src/views/components/Context/Context";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import LogoKBIcon from "./assets/katbook_logo.png";
// import KBView from "./views/KBView/KBView";
import { TheContent } from "./containers";
import EmployeeContext from "./Services/EmployeeContext";
import GlobalContext from "./Services/GlobalContext";
import CryptoJS from "crypto-js";
import {
  getUserProfile,
  KampusLogin,
  getKampusStudentLogin,
  deleteKampusStudentLogin,
  getAllLog,
} from "./Services/ApiService";
// import Model from "./views/components/Model/Model";
import UserLogModel from "./views/components/Model/UserLogModel";

import Danger from "./views/components/Toastify/Danger";
import Progress from "./views/components/Progress/Progress";
import Warning from "./views/components/Toastify/Warning";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));
// Pages
const Login = React.lazy(() => import("./views/Login/Login"));
const Register = React.lazy(() => import("./views/pages/register/Register"));
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));

const initialState = {
  isAuthenticated: false,
  katbookInitiation: "",
  userType: null,
  user: null,
  email: "",
  _id: "",
  token: null,
  loggedUserType: "",
  userTypeId: "",
  userTypes: "",
  UserTypecode: "",
  route: "",
  subRoute: "",
  companyId: "6007cfc79052d71fec82fef2",
  companyName: "KATBOOK CONTENT DEVELOPMENT",
  institutionName: "",
  student: false,
  userLogger: false,
  institutionUser: false,
  log: "",
  loginUserType: false,
  url: "",
  teacherSelectedSection: "",
  teacherSelectedclass: "",
  leftBar: false,
  rightBar: false,
  stateColor: "#00356D",
};
const reducer = (state, action) => {
  switch (action.type) {
    case "Profile":
      localStorage.setItem("token", action.token);
      localStorage.setItem("student", action.student);
      console.log(action, "ACTIONS LISTS");
      localStorage.setItem(
        "instDetail",
        JSON.stringify({ userName: action.user, password: action.password })
      );
      return {
        ...state,
        isAuthenticated: true,
        token: action.token,
        _id: action._id,
        email: action.email,
        user: action.user,
        adminName: action.adminName,
        loggedUserType: action.loggedUserType,
        userType: action.userType,
        UserTypecode: action.UserTypecode,
        userTypeId: action.userTypeId,
        userTypes: action.userTypes,
        institutionName: action.institutionName,
        student: action.student,
        standard: action.standard,
        section: action.section,
        refInstID: action.refInstID,
        variableID: action.variableID,
        loginUserType: action.loginUserType,
        url: action.url,
      };
    case "LOGIN":
      console.log("hihihihiii", action);
      console.log("hihihihiii", state);
      localStorage.setItem("token", action.token);
      localStorage.setItem("student", action.student);
      localStorage.setItem(
        "instDetail",
        JSON.stringify({ userName: action.user, password: action.password })
      );
      return {
        ...state,
        isAuthenticated: true,
        token: action.token,
        _id: action._id,
        email: action.email,
        user: action.user,
        adminName: action.adminName,
        loggedUserType: action.loggedUserType,
        userType: action.userType,
        UserTypecode: action.UserTypecode,
        institutionName: action.institutionName,
        userTypeId: action.userTypeId,
        userTypes: action.userTypes,
        student: action.student,
        standard: action.standard,
        section: action.section,
        refInstID: action.refInstID,
        variableID: action.variableID,
        loginUserType: action.loginUserType,
      };
    case "KBC_ROUTES":
      // console.log("hihihihiii", action.route);
      return {
        ...state,
        route: action.route,
        subRoute: action.subRoute,
      };
    case "BOOK_CREDS":
      // console.log("hihihihiii", action.bookCreds);
      return {
        ...state,
        bookCreds: action.bookCreds,
      };

    case "KATBOOKINITIATION":
      // console.log("hihihihiii", action.katbookInitiation);
      localStorage.setItem("katbookInitiation", action.katbookInitiation);
      return {
        ...state,
        katbookInitiation: action.katbookInitiation,
      };

    case "CREDS":
      return { ...state, creds: action.creds };
    case "BM":
      return { ...state, BookMarkList: action.BookMarkList };
    case "NOTES":
      return { ...state, Notes: action.Notes };
    case "ClarifyID":
      return { ...state, ...action };
    case "LOGOUT":
      localStorage.clear();
      return {
        ...state,
        isAuthenticated: false,
        katbookInitiation: "",
        userType: null,
        user: null,
        email: "",
        _id: "",
        token: null,
        UserTypecode: "",
        route: "",
        subRoute: "",
        institutionName: "",
        section: "",
        userTypeId: "",
        userTypes: "",
        loggedUserType: "",
        standard: "",
        student: false,
        refInstID: "",
        variableID: "",
        loginUserType: "",
      };
    case "UserLogger":
      return {
        ...state,
        userLogger: !state.userLogger,
      };
    case "LOG":
      // console.log(action);
      return {
        ...state,
        log: action.log,
      };
    case "ScrollFrom":
      // console.log(action);
      return {
        ...state,
        ...action,
      };
    case "CLASS_SELECT":
      return {
        ...state,
        ...action,
      };
    case "STAFF_TYPE":
      return {
        ...state,
        ...action,
      };
    case "Left_fold":
      return {
        ...state,
        ...action,
      };
    case "Right_fold":
      return {
        ...state,
        ...action,
      };
    case "state_color":
      return {
        ...state,
        ...action,
      };
    default:
      return state;
  }
};

const App = () => {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  // const [token, setToken] = useState(state.token);
  const [isloading, setIsloading] = useState(false);

  const [stream, setStream] = useState(null);

  // state = {
  //   language: 'en'
  // }

  // const languageHandler = (e) => {
  //   e.preventDefault();
  //   let lang = e.target.value;
  //   this.setState(prevState => ({
  //     language: lang
  //   }))
  // }

  useEffect(() => {
    const getProfile = async (token, value, secondData) => {
      // alert('getProfile')
      // console.log(token, "VA");
      console.log(value, "VA");
      console.log(secondData, "VA");
      console.log(token, "VA");
      if (!token && !value) {
        dispatch({ type: "LOGOUT" });
      } else if (token) {
        // alert("567")
        let response;
        try {
          response = await getUserProfile(token);
          if (response.success) {
            console.log(response, "res");
            // console.log(response.success, "test");
            dispatch({
              type: "Profile",
              token: token,
              _id: response.value.userId,
              email: "",
              user: response.value.userId,
              loggedUserType: response.value.userTypeId.usertype,
              userType: response.value.userTypeId.usertype === "Teacher" ? "Teaching" : response.value.userTypeId.usertype === "Parent" ? "Teaching" : response.value.userTypeId.usertype,
              userTypeId: response.value.userTypeId._id,
              UserTypecode: response.value.userTypeId.code,
              userTypes: response.value.userTypeId,
              student: false,
            });
            dispatch({ type: "CREDS", creds: response.value });
          } else if (!response.success) {
            toast.error(
              <Danger body={"Session was time out , Please login again!."} />
            );
            dispatch({ type: "LOGOUT" });
            //window.location = "/";
          }
        } catch (e) {
          console.log(e,"eeeeeeeeeeeeeeeeeeeeeeee");
          toast.warning(
            <Warning body={"Something went worng please login!."} />
          );
          dispatch({ type: "LOGOUT" });
          // window.location = "/";
        }
      } else if (value || secondData.success) {
        var values = JSON.parse(value);

        let res;
        try {
            res = await KampusLogin({
              userId: values.userName || secondData.user.username,
              password: values.password || secondData.user.code,
              role: values.role || secondData.user.role,
  
            });

          console.log(res, "KAMPUS LOGIN SUCCESS");
          if (res.success === true) {
            dispatch({ type: "state_color", stateColor: "#294d4a" });
            dispatch({ type: "CREDS", creds: res.value });

            const decryptedRegUser = CryptoJS.AES.decrypt(res.value.code, "!@#$%^");
            const RegUserRes = decryptedRegUser.toString(CryptoJS.enc.Utf8);

            dispatch({
              type: "Profile",
              user: res.value.userId,
              userType: res.value.userTypeId.usertype === "Teacher" ? "Teaching" : res.value.userTypeId.usertype === "Parent" ? "Teaching" : res.value.userTypeId.usertype,
              adminName: res.value.firstName+' '+res.value.lastName,
              value: res.value,
              token: "",
              password: RegUserRes,
              institutionName: res.value.schoolName,
              section: res.value.standard[0]._id,
              standard: res.value.standard[0]._id,
              refInstID: 1,
              variableID: 1,
              loggedUserType: res.value.userTypeId.usertype,
              loginUserType: res.value.userTypeId.usertype === "Teacher" ? "Teaching" : res.value.userTypeId.usertype === "Parent" ? "Teaching" : res.value.userTypeId.usertype,
              student: res.value.userTypeId.usertype === "Teacher" || res.value.userTypeId.usertype === "Parent" ? false : true,
              url: values.url || "",
            });
            // let address = JSON.parse(res.value.kampusAddress);
            
            localStorage.setItem("token", res.token);
            localStorage.setItem("AccessToken", res.token);
            localStorage.setItem("userCreds", JSON.stringify(res.value));
            // deleteKampusStudentLogin();
          } else if (res.success === false) {
            // alert()
            toast.error(<Danger body={res.error} />);
            dispatch({ type: "LOGOUT" });
          }
        } catch (e) {
          // console.log(e);
          console.log(e,"eeeeeeeeeeeeeeeeeeeeeeee");
          toast.warning(
            <Warning body={"Something went worng please login!."} />
          );
          dispatch({ type: "LOGOUT" });
          //window.location = "/";
        }
      }
      setIsloading(false);
    };

    const getFuc = async () => {
      const key = "KPOSTKatBooks#$*(@#)!!";
      const BooksViewURL = window.location.href.split("BookView/");
      
      var MergeData, datas;
      if(BooksViewURL.length > 1){
      const bytes = CryptoJS.AES.decrypt(BooksViewURL[1], key);
      // alert(BooksViewURL[1])
      const datadecrype = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      MergeData = `${BooksViewURL[0]}BookView/${datadecrype}`;
      // const MergeData = '';
      // alert(MergeData)
      datas = String(MergeData).replaceAll('%22', '').replaceAll('%20', '').replaceAll("%EF%BB%BF", "");
      }
      // alert(datas && datas.search("username"))
      if(datas && datas.search("username") > 0){
        // alert("token")
        localStorage.removeItem("token");
        localStorage.clear();
      } else {
        if(BooksViewURL[1] !== undefined && BooksViewURL[1] !== "undefined" &&
        BooksViewURL[1] !== null && BooksViewURL[1] !== "null" && BooksViewURL[1] !== ""){

        } else {
          // alert("exit")
          dispatch({ type: "LOGOUT" });
          window.close();
        }
      }

      if (MergeData !== undefined && MergeData !== null && MergeData.split("/").indexOf("TeacherView") > 0) {
        // alert("teacher")
        // alert("The URL of this page is: " + window.location.href + "1213412");
        const urlArr = MergeData.split("/");
        // console.log(urlArr, "URLARR");
        // const creds = JSON.parse(urlArr[urlArr.length - 1]);
        // alert(urlArr[urlArr.length - 1]);

        let decoded = atob(urlArr[urlArr.length - 1]);
        let creds = JSON.parse(decoded);
        // console.log(creds, "DECODED");
        let data = JSON.stringify({
          userName: creds.logname,
          password: creds.logpassword,
          url: urlArr[urlArr.length - 1],
        });
        // console.log(data, "DATA@!##%$@^");
        setIsloading(true);
        return getProfile("", data, "");
      }
      if (MergeData !== undefined && MergeData !== null && MergeData.split("/").indexOf("katbook") > 0) {
        // alert("katbook")
        // alert("The URL of this page is: " + window.location.href + "1213412");
        const urlArr = MergeData.split("/");
        // console.log(urlArr, "URLARR");
        // const creds = JSON.parse(urlArr[urlArr.length - 1]);
        // alert(urlArr[urlArr.length - 1]);

        let decoded = atob(urlArr[urlArr.length - 1]);
        let creds = JSON.parse(decoded);
        // console.log(creds, "DECODED");
        let data = JSON.stringify({
          userName: creds.logname,
          password: creds.logpassword,
          url: urlArr[urlArr.length - 1],
        });
        // console.log(data, "DATA@!##%$@^");
        setIsloading(true);

        return getProfile("", data, "");
      }
      // var addres = address.ip();
      // console.log(addres, "ipconfigd");
      var token = await localStorage.getItem("token");
      var value = await localStorage.getItem("instDetail");
      var secondData;
      // alert(token)
      // if (!token && datas && datas.search("username") > 0) {
      //   try {
      //     secondData = await getKampusStudentLogin();
      //     if (secondData.success) {
      //       localStorage.setItem("instDetail", JSON.stringify(secondData.user));
      //       value = await localStorage.getItem("instDetail");
      //     }
      //     else {
      //       console.log(secondData.user,"getKampusStudentLogin");
      //       value = '';
      //     }
      //   } catch (error) {
      //     toast.error(<Danger body={error} />);
      //   }
      // }
      setIsloading(true);
      console.log(datas,value,secondData,"secondDatasecondData");
      if (datas && !value && !secondData) {
        // alert(datas)
        let slenght = datas.search("{");
        let elenght = datas.search("}/");
        // alert(slenght)
        // alert(elenght)
        if (elenght !== 0 && slenght !== 0 && slenght < elenght) {
          // alert(2)
          datas = datas
            .replaceAll("%22", "")
            .replaceAll("%20", "")
            .replaceAll("%EF%BB%BF", "").replaceAll('"','');
          var n = datas.search("username");
          var j = datas.search(",code");
          var k = datas.search("}}");
          let user = datas.substr(n, j - n);
          user = user.substr(user.search(":") + 1, user.length);
          let code = datas.substr(j + 1, k - j - 1);
          code = code.substr(code.search(":") + 1, code.length);
          let pswd = code.split("|")[0];
          let role = code.split("|")[1];
          // alert(code)
          //console.log(JSON.parse(datas), "project");
          // alert(user)
          // alert(code)
          if (user && code) {
            value = JSON.stringify({ "userName": user, "password": pswd, "role": role });
          }
        }
      }
      // alert()
      // console.log(value, "project");
      getProfile(token, value, secondData);
    };
    // setInterval(() => {
    getFuc();
    // }, 2000);
    
    // // disable right click
    // let elm = document.getElementById("root");
    // document.addEventListener('contextmenu',
    //             event => event.preventDefault());
    // // elm.innerHTML = "Right click disabled";

    // Enable microphone option
    const getMicrophoneAccess = async () => {
      try {
        const mediaStream = await navigator.mediaDevices.getUserMedia({ audio: true });

        // Set the stream to the state
        setStream(mediaStream);

        // You can now use the 'mediaStream' object to access the microphone data
        // For example, you can create an audio element and set its srcObject
        const audioElement = document.getElementById('audioElement');
        if (audioElement) {
          audioElement.srcObject = mediaStream;
        }
      } catch (error) {
        console.error('Error accessing microphone:', error);
      }
    };

    // Call the function to get microphone access when the component mounts
    getMicrophoneAccess();

    // Cleanup: Stop the stream when the component unmounts
    return () => {
      if (stream) {
        stream.getTracks().forEach((track) => track.stop());
      }
    };

  }, []);


  return (
    <Authcontext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      <GlobalContext>
        <EmployeeContext>
          <HashRouter>
            <React.Suspense fallback={loading}>
              {!isloading ? (
                <Switch>
                  {!state.isAuthenticated ? (
                    <>
                      <Route
                        exact
                        path="/"
                        name="Login Page"
                        render={(props) => <Login {...props} />}
                      />
                      <Route
                        exact
                        path="/register"
                        name="Register Page"
                        render={(props) => <Register {...props} />}
                      />
                      <Route
                        exact
                        path="/404"
                        name="Page 404"
                        render={(props) => <Page404 {...props} />}
                      />
                      <Route
                        exact
                        path="/500"
                        name="Page 500"
                        render={(props) => <Page500 {...props} />}
                      />
                    </>
                  ) : (
                    state.isAuthenticated && (
                      <Route
                        path="/"
                        name="Home"
                        render={(props) => {
                          //  console.log(props.location.pathname.split('/'),"PROP");
                          const key = "KPOSTKatBooks#$*(@#)!!";
                          const BooksViewURL = window.location.href.split("BookView/");
                          if(BooksViewURL.length > 1){
                          const bytes = CryptoJS.AES.decrypt(BooksViewURL[1], key);
                          const datadecrype = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

                          const newDatas = Object.keys(props.location).forEach((property) => {
                              if(property === 'pathname'){
                                if(state.userType === "Teaching" || state.userType === "Student"){
                                props.location[property] = `/kampus/BookView/${datadecrype}`;
                                } else {
                                  props.location[property] = `/BookView/${datadecrype}`; 
                                }
                              }
                            });

                          }

                          if (
                            props.location.pathname
                              .split("/")
                              .includes("BookView") ||
                            props.location.pathname
                              .split("/")
                              .includes("LinkView")
                          ) {
                            //  console.log(props,"PROP");

                            return <TheContent {...props} style />;
                          }
                          if (
                            props.location.pathname
                              .split("/")
                              .includes("bookList")
                          ) {
                            //  console.log(props,"PROP");
                            return <TheContent {...props} style />;
                          } else {
                            // window.close();
                            // return <TheLayout {...props} />;
                          }
                        }}
                      />
                    )
                  )}
                </Switch>
              ) : (
                <CRow
                  className={"progressBody"}
                  style={{
                    alignItems: "center",
                    alignContent: "center",
                    justifyContent: "center",
                  }}
                >
                  <CCol
                    lg="12"
                    style={{ textAlign: "center", marginTop: "15%" }}
                  >
                    <img src={LogoKBIcon} alt="img" />
                    <br />
                    <br />
                    {/* <CSpinner size="lg" variant="grow" /> */}
                    <center>
                      <Progress />
                    </center>
                  </CCol>
                </CRow>
              )}
            </React.Suspense>
          </HashRouter>
        </EmployeeContext>
      </GlobalContext>
      <ToastContainer autoClose={3000} />
      {/* <UserLogModel /> */}
    </Authcontext.Provider>
  );
};

export default App;
